import React, { useCallback, useContext, useEffect, useRef } from 'react'
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
// import { SignageClient } from '../ApiClient/SignageClient';
import { Image, Text, Flex, Box, Button, Switch } from '@chakra-ui/react';
import { SignageContentsManager } from '../Common/contents/SignageContentsManager';
import { CmdObj, StreamerCommandManager } from '../Common/contents/StreamerCommandManager';
import { AppContext } from 'layouts/AppCommons';
import { useKey } from 'react-use';
import { SignageLayoutList, SignageStateList } from 'views/Pages/Users/SignageTemplateUpdate';
import { ImArrowLeft, ImArrowRight } from 'react-icons/im'
import { Separator } from 'components/Separator/Separator';
import { MediaClient } from "../ApiClient/MediaClient";
import wsConfig from "../../../ws_config.json";
import { BrowserFaceCapture } from '../wsClient/BrowserFaceCapture';
import { SpeechSynthesizer } from 'microsoft-cognitiveservices-speech-sdk';

const browserFaceCapture = new BrowserFaceCapture();
const defaultCharacter = wsConfig.defaultCharacter;
// const defaultCharacter = "NE_custom";

// Assets
export default function PixelStreamer(props: any) {
    const { appContext, setAppContext } = useContext(AppContext);
    // console.log(`${new Date().toISOString()}[debug]PixelStreamer render.`);


    // PixelStreamへのコマンド送信
    const sendToStreamer = (cmd: string) => {
        if (props.inServiceOperation) {
            props.wsc.emitUIInteraction(cmd);
        }
    };

    // UE4コマンドリスト
    const commandList: CmdObj[] = new StreamerCommandManager().get();

    // 画面内で更新されるデータ
    const [standbyImg, setStandbyImg] = React.useState("");
    const [ratio, setRatio] = React.useState({
        w: 1080,
        h: 1920
    });
    const [videoWidth, setVideoWidth] = React.useState(1080);
    const [videoHeight, setVideoHeight] = React.useState(1920);

    // const [streamingTransform, setStreamingTransform] = React.useState("");
    const [selectCharacter, setSelectCharacter] = React.useState(null);
    // 実証実験用に追加したモーションボタンを表示するキャラクター名
    const isDispFullMotionCharacters = ["NE_custom", "Kei", "Kei_short", "Kento"];

    const [sendMotionTime, setSendMotionTime] = React.useState(0);

    const [isLoadingView, setIsLoadingView] = React.useState(true);
    const [isViewPixelStreaming, setIsViewPixelStreaming] = React.useState(false);
    const [shareStream, setShareStream] = React.useState(null);
    const [isShareScreen, setIsShareScreen] = React.useState(false);
    const [currentTemplate, setCurrentTemplate] = React.useState(null);
    // 翻訳テロップの表示/非表示
    const [isViewTranslateTelop, setIsViewTranslateTelop] = React.useState(false);

    //AI会話リストのテストデータ
    const systemTalkListTest = ["本日はどのようなご用件ですか", "発行日はいつですか", "どこに提出しますか"];
    const userTalkListTest = ["卒業証明書が欲しいです", "なるはやで", "大学入学共通テストです"];

    //AI会話リストのデフォルト値
    // const systemTalkListDefault = ["本日はどのようなご用件ですか？"];
    const systemTalkListDefault = ["ようこそいらっしゃいませ。何かお手伝いいたしますか？"];

    //AI会話リストを保存する
    const [systemTalkList, setSystemTalkList] = React.useState(systemTalkListDefault);
    const [userTalkList, setUserTalkList] = React.useState([]);
    const [talkStep, setTalkStep] = React.useState(0);
    const [textFloorMap, setTextFloorMap] = React.useState(`http://ksin-app.unicast.ne.jp/images/generalReception/demo/default.png`);
    // const [textFloorMap, setTextFloorMap] = React.useState(`http://localhost:3002/images/generalReception/demo/default.png`);

    const getSystemTalk = (boxId: number) => {
        const talkIndex = Math.floor(talkStep / 3) + boxId - 1;
        return systemTalkList[talkIndex];
    }

    const getUserTalk = (boxId: number) => {
        const talkIndex = Math.floor(talkStep / 3) + boxId - 1;
        return userTalkList[talkIndex];
    }

    function synthesizeSpeech(message :string) {
        const speechConfig = SpeechSDK.SpeechConfig.fromSubscription("B6gf9DdETUMujYZyIBAbUesjIlMh8P0p3yk6NuS1amHzC1ZlgqibJQQJ99BCACi0881XJ3w3AAAYACOG1z9I", "japaneast");
        const audioConfig = SpeechSDK.AudioConfig.fromDefaultSpeakerOutput();
        speechConfig.speechSynthesisVoiceName = "ja-JP-NanamiNeural"; 
    
        const speechSynthesizer = new SpeechSynthesizer(speechConfig, audioConfig);
        speechSynthesizer.speakTextAsync(
            message,
            result => {
                if (result) {
                    speechSynthesizer.close();
                    return result.audioData;
                }
                return null;
            },
            error => {
                console.log(error);
                speechSynthesizer.close();
            });
    }

    // AI会話が更新されたとき
    // AI側で発言の更新があれば会話表示インデックス更新
    // システム側の発言更新
    useEffect(() => {
        if (props.systemTalkLatest) {
            setSystemTalkList([...systemTalkList, props.systemTalkLatest]);

            let floorMapInfo = "default";
            // if(props.systemTalkLatest.includes("EAST TOWER") && props.systemTalkLatest.includes("15階")) {
            //     floorMapInfo = "east-15f";
            // } else if(props.systemTalkLatest.includes("EAST TOWER") && props.systemTalkLatest.includes("17階")) {
            //     floorMapInfo = "east-17f";
            // } else if(props.systemTalkLatest.includes("WEST TOWER") && props.systemTalkLatest.includes("23階")) {
            //     floorMapInfo = "west-23f";
            // } else {
            //     floorMapInfo = "mito_office";
            // }
            if(props.systemTalkLatest.includes("バーカウンター")) {
                floorMapInfo = "bar.jpg";
            } else if(props.systemTalkLatest.includes("ロボット")) {
                floorMapInfo = "robot.jpg";
            } else if(props.systemTalkLatest.includes("会議室")) {
                floorMapInfo = "meeting-room.png";
            } else if(props.systemTalkLatest.includes("マッサージ") || props.systemTalkLatest.includes("リフレッシュ")) {
                floorMapInfo = "relax.png";
            } else if(props.systemTalkLatest.includes("畳") || props.systemTalkLatest.includes("子育て")) {
                floorMapInfo = "koagari.jpg";
            } else {
                floorMapInfo = "mito_office.png";
            }
            setTextFloorMap(`http://ksin-app.unicast.ne.jp/images/generalReception/demo/${floorMapInfo}`);
            // setTextFloorMap(`http://localhost:3002/images/generalReception/demo/${floorMapInfo}`);
            

            // 合成音声出力
            synthesizeSpeech(props.systemTalkLatest);

            setTalkStep((prev) => {
                return prev + 1;
            })
        }
    }, [props.systemTalkLatest]);
    // useEffect(() => {
    //     if(systemTalkList.length > 0) {
    //         synthesizeSpeech(systemTalkList[systemTalkList.length - 1]);
    //     }
    // }, [systemTalkList]);

    // ユーザー側の発言更新
    useEffect(() => {
        if (props.userTalkLatest) {
            setUserTalkList([...userTalkList, props.userTalkLatest]);
            let delta;
            // 2周目以降はユーザー発言で2ステップ進める
            if (talkStep < 2) {
                delta = 1;
            } else {
                delta = 2;
            }

            setTalkStep((prev) => {
                return prev + delta;
            })
        }
    }, [props.userTalkLatest]);

    // キーボードイベント(開発用)
    // useKey("t", () => {
    //     setTalkStep((prev) => {
    //         return prev + 1;
    //     })
    // });

    useEffect(() => {
        console.log(`[debugAITalk]talkStep: ${talkStep}`);
        console.log(`[debugAITalk]system01: ${getSystemTalk(1)}`);
        console.log(`[debugAITalk]user01: ${getUserTalk(1)}`);
        console.log(`[debugAITalk]system02: ${getSystemTalk(2)}`);

    }, [talkStep]);

    const templateRef = React.useRef(null);
    const [isDecodePixelStreaming, setIsDecodePixelStreaming] = React.useState(false);
    const decodeRef = React.useRef(0);

    // [fix]横長レイアウト画面共有時のアバター表示修正
    const [isWideWipeAvatar, setIsWideWipeAvatar] = React.useState(false);

    // iPhoneキャプチャ・ブラウザキャプチャ切替
    // const [isBrowserCapture, setIsBrowserCapture] = React.useState(false);
    const captureModeRef = React.useRef("notSet");
    const [isInitBrowserCapture, setIsInitBrowserCapture] = React.useState(false);
    const browserCaptureCmd = ["FaceUseWebcam", "iPhone"];
    const iPhoneCaptureCmd = ["FaceUseIPhone", "iPhone"];
    const toggleBrowserCapture = () => {
        if (captureModeRef.current === "iPhone") {
            captureModeRef.current = "FaceUseWebcam";
        } else if (captureModeRef.current === "FaceUseWebcam") {
            captureModeRef.current = "iPhone";
        }
    }

    useEffect(() => {
        if (captureModeRef.current === "FaceUseWebcam") {
            // 初回のみブラウザによる顔キャプチャモジュール読み込み
            if (props.inServiceOperation && !isInitBrowserCapture) {
                browserFaceCapture.init();
                // ブラウザキャプチャモジュール初期化済みフラグをON
                setIsInitBrowserCapture(true);
            }
            if (isDecodePixelStreaming) {
                console.log(`isBrowserCapture: true, FaceUseWebcam`);
                props.wsc.emitUIInteraction(browserCaptureCmd);
                setTimeout(() => {
                    if (decodeRef.current > 0) {
                        console.log(`(ReSend) isBrowserCapture: true, FaceUseWebcam`);
                        props.wsc.emitUIInteraction(browserCaptureCmd);
                    }
                }, 2000);
            }
        } else if (captureModeRef.current === "iPhone") {
            if (isDecodePixelStreaming) {
                console.log(`isBrowserCapture: false, FaceUseIPhone`);
                props.wsc.emitUIInteraction(iPhoneCaptureCmd);
            }
        }
    }, [captureModeRef.current, isDecodePixelStreaming, isShareScreen]);

    useEffect(() => {
        // if (isViewTranslateTelop) {
        //     if (props.mainTelop && props.mainTelop.length > 0) {
        //         makeBreakLineTelop(props.mainTelop);
        //     } else {
        //         makeBreakLineTelop("Currently recognizing...");
        //     }
        // } else {
        //     if (currentTemplate && currentTemplate.telop && currentTemplate.is_view_telop) {
        //         makeBreakLineTelop(currentTemplate.telop);
        //     } else {
        //         makeBreakLineTelop("");
        //     }
        // }
        if (currentTemplate && currentTemplate.telop && currentTemplate.is_view_telop) {
            makeBreakLineTelop(currentTemplate.telop);
        }
    }, [isViewTranslateTelop, props.mainTelop, currentTemplate]);

    // デコードされていなければローディング表示に切り替えるメソッド
    const checkDecodedPixelStream = () => {
        let decodedStr = document.getElementById("stats-frames-decoded");
        if (decodedStr) {
            const trimed = decodedStr.innerText.replace(/Frames|Decoded:|\s|,/g, '');
            let decodedNum = 0;
            try {
                decodedNum = parseInt(trimed);
                // console.log(`${new Date().toISOString()}[debug]checkDecodedPixelStream trimed: ${trimed}`);
                // console.log(`${new Date().toISOString()}[debug]checkDecodedPixelStream decodedNum: ${decodedNum}`);
                if (decodedNum > 0) {
                    decodeRef.current = decodedNum;
                    setIsDecodePixelStreaming(true);
                    if (props.inServiceOperation) {
                        setStreamerCamera(templateRef.current);
                    }
                    // fix キャラクター変更が反映されないことがあるバグの修正
                    setSelectCharacter((pre) => {
                        // console.log(`${new Date().toISOString()}[debug]PixelStreamer setStreamerCharacter after decoded: ${pre}`);
                        setStreamerCharacter(pre);
                        return pre;
                    })



                    // fix KSIN-239 アバターchの前回キャラクターと異なる場合のみ描画を待つため2秒後に表示する
                    let waitTime = 2000;
                    setAppContext((pre) => {
                        // console.log(`${new Date().toISOString()}[debug]PixelStreamer appContext.lastUseCharacter: ${pre.lastUseCharacter}, selectCharacter: ${selectCharacter}`);
                        if (pre.lastUseCharacter === selectCharacter) {
                            // console.log(`${new Date().toISOString()}[debug]PixelStreamer channelCharacter is NO swiching.`);
                            waitTime = 0;
                        }
                        return { ...pre };
                    })

                    setTimeout(() => {
                        // fix PixelStreamキャラクター変更後のブラウザ・iPhoneキャプチャ切替
                        // setTimeout(() => {
                        // setIsBrowserCapture((pre_isBrowserCapture) => {
                        if (captureModeRef.current === "FaceUseWebcam") {
                            // console.log(`(ReSend) isBrowserCapture: true, FaceUseWebcam after character changed.`);
                            props.wsc.emitUIInteraction(browserCaptureCmd);
                        } else if (captureModeRef.current === "iPhone") {
                            // console.log(`isBrowserCapture: false, FaceUseIPhone after character changed.`);
                            props.wsc.emitUIInteraction(iPhoneCaptureCmd);
                        }
                        //     return pre_isBrowserCapture;
                        // });
                        // }, 1000);

                        setIsLoadingView(false);
                        setTimeout(checkDecodedPixelStream, 5000);
                    }, waitTime);
                } else {
                    setIsDecodePixelStreaming(false);
                    setIsLoadingView(true);
                    setTimeout(checkDecodedPixelStream, 500);
                }
            } catch (e) {
                console.error("Failed parseInt decodedFrames");
            }
        } else {
            setTimeout(checkDecodedPixelStream, 1000);
        }
    }

    // キーボードイベント

    const isViewPixelStreamingStateList = [
        "Active"
    ]
    const [isLoadingVideo, setIsLoadingVideo] = React.useState(false)
    const handleClickVideoLoader = () => {
        setIsLoadingVideo(true)
    }

    // サイネージに表示する要素のレイアウト
    const signageContentsManager = new SignageContentsManager();
    const defaultLayout = signageContentsManager.get("vertical", "onlyAvatar");

    const [signageContents, setSignageContents] = React.useState(defaultLayout);

    // テロップの改行対応
    const [telopList, setTelopList] = React.useState([]);

    // メディアコンテンツの種類
    const [signageMediaType, setSignageMediaType] = React.useState("Video");
    const isVerticalButtonSetting = props?.settingButton?.direction === 'vertical';
    const settingsTouchButton = props?.settingButton?.data ?? []
    const showButtonWithLayoutChange = ratio.h - ratio.w >= 0;
    // モニタリングリクエストを受けたら待機中ビデオに切り替える
    useEffect(() => {
        console.log(`[confirm callRequestTime]`);
        if (props.callRequestTime) {
            console.log(`[callRequestTime]`);
            console.log(`[callRequestTime] props.isMonitoring: ${props.isMonitoring}`);
            if (props.inSignage) {
                if (props.signageState === SignageStateList.Standby && props.isMonitoring) {
                    setIsViewPixelStreaming(false);
                }
            }
        }
    }, [props.callRequestTime]);


    const sendMotionCommand = (motionType: string) => {
        // 前回モーションより5秒以上経過していたら実行
        console.log(sendMotionTime);
        const now = new Date().getTime();
        if (now - sendMotionTime > 5000) {
            sendToStreamer(`motion-${motionType}`);
            setSendMotionTime(new Date().getTime());
        }
    };

    const toggleIsShareScreen = useCallback(() => {
        setIsShareScreen((prevState) => {
            if (prevState) {
                closeShareScreen();
            } else {
                shareScreen();
            }
            return !prevState
        });
    }, []);

    const shareScreen = () => {
        if (props.templateTable) {
            props.setIsShareScreen(true);
        }
    };

    const closeShareScreen = () => {
        if (props.templateTable && !props.inTemplateEditor) {
            props.setIsShareScreen(false);
        }

        setStandbyImg(signageContents.avatar.src.replace(/monitoringCharacter/g, selectCharacter));
    };

    useEffect(() => {
        if (currentTemplate) {
            onChangeTemplate();

            // [fix]横長レイアウト時のワイプ表示修正
            if (currentTemplate.layout && isViewPixelStreaming && currentTemplate.layout === SignageLayoutList.wipe) {
                if (ratio && ratio.h < ratio.w) {
                    setIsWideWipeAvatar(true);
                }
            } else {
                setIsWideWipeAvatar(false);
            }
        }
    }, [currentTemplate]);

    const videoRef = useRef<HTMLVideoElement>(null);
    useEffect(() => {
        videoRef.current?.load();
        videoRef.current?.addEventListener('loadeddata', () => {
            videoRef.current?.play();
            console.log("[debug-test videoRef play]", videoRef.current)
        });
        console.log(standbyImg);
        // オペレーター画面の場合準備完了フラグ更新
        if (props.inServiceOperation) {
            props.changeIdleVideoStatus(true);
        }
    }, [standbyImg]);

    const mediaVideoRef = useRef<HTMLVideoElement>(null);

    const checkMediaType = (image_url: string) => {
        if (image_url.length) {
            const allowImageTypes = ["png", "jpeg", "jpg"];
            const allowVideoTypes = ["mp4"];
            let extension = image_url.split('.').pop();

            if (allowImageTypes.indexOf(extension) != -1) {
                setSignageMediaType("Image");
            } else if (allowVideoTypes.indexOf(extension) != -1) {
                setSignageMediaType("Video");
                setTimeout(() => {
                    mediaVideoRef.current?.load();
                    mediaVideoRef.current?.play();
                    console.log("[debug-test mediaRef play]", mediaVideoRef.current)
                }, 500)
            } else {
                console.error("signageMedia is not allowed type.");
            }
        }
    };
    const mediaClient = new MediaClient();
    const [media, setMedia] = React.useState([])
    const getMedia = async () => {
        await mediaClient.get()
            .then((data: any) => {
                setMedia([...data]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        checkMediaType(signageContents.media.src);
        getMedia()
        console.log(media)
    }, [signageContents.media.src]);

    useEffect(() => {
        if (ratio) {
            calcVideoSize();
        }
    }, [ratio]);

    // 各メニューの固定サイズ(px)
    const menuSizeList = {
        leftMenuWidth: 275,
        editorButtonsWidth: 100,
        editorSceneSelecterWidth: 200,
        commonHeaderHeight: 100,
        editorHeaderHeight: 100
    }

    // リサイズ対応
    const calcVideoSize = () => {
        let width;
        let height;

        if (props.inTemplateEditor || props.inServiceOperation) {
            if (ratio && ratio.h > ratio.w) {
                height = calcVideoHeightForOperator();
                width = height * ratio.w / ratio.h;
            } else {
                width = calcVideoWidthForOperator();
                height = width * ratio.h / ratio.w;
            }
        } else {
            if (ratio && ratio.h > ratio.w) {
                height = window.innerHeight;
                width = height * ratio.w / ratio.h;
            } else {
                width = window.innerWidth;
                height = width * ratio.h / ratio.w;
            }
        }
        setVideoWidth(width);
        setVideoHeight(height);
    }

    const calcVideoWidthForOperator = () => {
        const ms = menuSizeList;

        // NOTE: モニタリング中および接客中の際、サイネージ側の映像とオペレーター側の映像の表示比率をおおよそ4：6にする
        if (props.isViewTheirVideo && !props.isMonitoring) {
            return (window.innerWidth - (ms.leftMenuWidth + ms.editorSceneSelecterWidth)) * 0.6;

        } else if (props.inServiceOperation) {
            return (window.innerWidth - (ms.leftMenuWidth)) * 0.6;
        }
        else {
            return window.innerWidth - (ms.leftMenuWidth + ms.editorButtonsWidth + ms.editorSceneSelecterWidth) - 60;
        }
    }
    const calcVideoHeightForOperator = () => {
        const ms = menuSizeList;
        return window.innerHeight - (ms.commonHeaderHeight + ms.editorHeaderHeight) - 30;
    }

    window.addEventListener('resize', function () {
        calcVideoSize();
    }, false);


    // テンプレートに応じたコマンドをstreamerに送信
    const setStreamerCamera = (template) => {
        // カメラ決定
        const ratioConf = template.width < template.height ? "vertical" : "wide";
        const selectCamera = template.layout === SignageLayoutList.mediaAndAvatar ? "andMedia" : "onlyAvatar";

        // 待機ムービー決定
        setStandbyImg(`/images/Characters/${selectCharacter}/${selectCharacter}_${ratioConf}_${selectCamera}.mp4`);

        // 背景設定
        if (wsConfig) {
            if (selectCharacter === "NE_custom") {
                sendToStreamer("bg_custom");
            } else {
                sendToStreamer("bg_default");
            }
        }

        let cmd = `camera-${ratioConf}_${selectCamera}`;

        // wideの場合カメラを90度回転
        if (ratioConf === "wide") {
            cmd = `${cmd}_rotate`;
        }

        sendToStreamer(cmd);
    }

    const setStreamerCharacter = (name) => {
        // console.log(`${new Date().toISOString()}[debug]PixelStreamer call setStreamerCharacter: ${name}`);
        // アバターの仮名変換 Kei -> Lin
        if (name === "Kei") {
            name = "Yukika";
        } else if (name === "Kei_short") {
            name = "Emiri";
        }

        // キャラクター決定
        setTimeout(() => {
            const cmd = `avatar-${name}`;
            sendToStreamer(cmd);
            // console.log(`${new Date().toISOString()}[debug]PixelStreamer setCharacter: ${name}`);
        }, 1000);

        // 背景設定
        setTimeout(() => {
            if (name === "NE_custom") {
                sendToStreamer("bg_custom");
            } else {
                sendToStreamer("bg_default");
            }
        }, 2000);
    }

    // マウント直後の単発処理
    useEffect(() => {
        if (props.selectCharacter) {
            setSelectCharacter(props.selectCharacter)
        }
        setRatio({
            w: 1080,
            h: 1920
        });
        console.log(`${new Date().toISOString()}[debug]PixelStreamer mounted.`);
        if (props.signageState === SignageStateList.Active) {
            console.log(`${new Date().toISOString()}[debug]call removePlayer in pointE`);
            props.wsc.removePlayer("reCreate");
        }
        setTimeout(() => {
            synthesizeSpeech(systemTalkListDefault[0]);
        }, 3000)
    }, []);

    useEffect(() => {
        // ユーザーの初期設定に応じてブラウザキャプチャを有効化
        if (props.isDefaultBrowserCapture) {
            captureModeRef.current = "FaceUseWebcam";
        }
    }, [props.isDefaultBrowserCapture]);


    useEffect(() => {
        // ユーザーの初期設定に応じてハンドトラッキングを設定
        if (props.handTrackingStatus) {
            browserFaceCapture.setHandTrackingStatus(props.handTrackingStatus);
        }
    }, [props.handTrackingStatus]);

    useEffect(() => {
        setSelectCharacter(props.selectCharacter ? props.selectCharacter : defaultCharacter);
        if (props.selectCharacter) {
            setSelectCharacter(props.selectCharacter)
        }
        setStandbyImg(signageContents.avatar.src.replace(/monitoringCharacter/g, props.selectCharacter));
    }, [props.selectCharacter]);

    useEffect(() => {
        if (isViewPixelStreaming) {
            // setStreamerCharacter(selectCharacter); // ここではまだストリーム接続していない場合がある
            mediaVideoRef.current ? mediaVideoRef.current.muted = true : ""
        }
    }, [isViewPixelStreaming]);

    useEffect(() => {
        // video要素にカメラ映像をセットして再生
        const videoElm: any = document.getElementById('share-media')
        if (shareStream && props.isShareScreen) {
            videoElm.srcObject = shareStream;
            videoElm.play();
            videoElm.style.display = "block";
        } else {
            setIsViewPixelStreaming(true);
            videoElm.srcObject = null;
            videoElm.style.display = "none";
        }
    }, [shareStream, props.isShareScreen]);

    useEffect(() => {
        // 画面共有開始・終了に関する処理(サイネージ・オペレーター共通)
        if (props.templateTable && props.signageState) {
            if (props.isShareScreen) {
                if (shareStream) {
                    setIsViewPixelStreaming(true);
                    currentTemplate.is_view_telop = false;
                    currentTemplate.telop = "";
                    currentTemplate.layout = SignageLayoutList.wipe;
                } else {
                    setIsViewPixelStreaming(false);
                    currentTemplate.is_view_telop = true;
                    currentTemplate.telop = "少々お待ちください";
                }
                setCurrentTemplate({ ...currentTemplate });
                templateRef.current = currentTemplate;
            } else {
                // オペレーター側・サイネージ側共通の画面共有終了時の処理
                if (shareStream) {
                    shareStream.getTracks().forEach((track) => {
                        track.stop();
                    })
                    setShareStream(null);
                }
                const receiveTemplate = props.templateTable.get(props.signageState).template;
                setCurrentTemplate({ ...receiveTemplate });
                templateRef.current = receiveTemplate;
            }
        }
    }, [props.isShareScreen, shareStream]);

    useEffect(() => {
        if (appContext.shareStream) {
            setShareStream(appContext.shareStream.clone());
        }
    }, [appContext.shareStream]);

    useEffect(() => {
        console.log(`${new Date().toISOString()}[debug]PixelStreamer props.signageState: ${props.signageState}`);
        // 画面共有の切り替え
        // PixelStreamingの表示切り替え
        if (isViewPixelStreamingStateList.indexOf(props.signageState) >= 0 && !props.inTemplateEditor) {
            console.log(`${new Date().toISOString()}[debug]PixelStreamer setIsViewPixelStreaming(true)`);
            setIsViewPixelStreaming(true);

            // 翻訳テロップ表示もOFF⇒ONにする
            setIsViewTranslateTelop(true);

            // デコードされていなければローディング表示に切り替え
            checkDecodedPixelStream();
        } else {
            console.log(`${new Date().toISOString()}[debug]PixelStreamer setIsViewPixelStreaming(false)`);
            setIsViewPixelStreaming(false);
            setIsShareScreen(false);
            closeShareScreen();
            // 翻訳テロップ表示もON⇒OFFにする
            setIsViewTranslateTelop(false);
        }

        if (props.signageState === SignageStateList.Standby || appContext.signageState === SignageStateList.Standby) {
            console.log(`${new Date().toISOString()}[debug]PixelStreamer props.signageState: ${props.signageState}`);
            console.log(`${new Date().toISOString()}[debug]PixelStreamer appContext.signageState: ${appContext.signageState}`);
            setIsViewPixelStreaming(false);
        }

        // レイアウトの初期設定取得
        let receiveTemplate;
        if (props.templateTable) {
            try {
                const currentState = props.signageState ? props.signageState : SignageStateList.Standby;
                receiveTemplate = props.templateTable.get(currentState).template
                setCurrentTemplate({ ...receiveTemplate });
                templateRef.current = receiveTemplate;

                if (isViewPixelStreamingStateList.indexOf(props.signageState) >= 0) {
                    // setStreamerCharacter(selectCharacter);
                }
            } catch (e) {
                console.error(`${new Date().toISOString()}[ERROR]receiveTemplate is Failed.`);
            }
        }
    }, [props.signageState, props.templateTable, appContext.signageId]);

    const makeBreakLineTelop = (original) => {
        if (original) {
            const list = original.split(/\n/);
            setTelopList([...list]);
        } else {
            setTelopList([]);
        }
    }

    const onChangeTemplate = () => {
        // サイネージテンプレートの変更
        const ratioConf = currentTemplate.height < currentTemplate.width ? "wide" : "vertical";
        setRatio({
            w: currentTemplate.width,
            h: currentTemplate.height
        });

        const template = signageContentsManager.get(ratioConf, currentTemplate.layout);


        if (props.templateTable && currentTemplate) {
            template.media.isDisp = currentTemplate.is_view_media;
            template.media.src = currentTemplate.media_src;

            template.logo.isDisp = currentTemplate.is_view_logo;
            template.logo.src = currentTemplate.logo_src;

            template.text.isDisp = currentTemplate.is_view_telop;
            template.text.msg = currentTemplate.telop;
            if (currentTemplate.telop.length && currentTemplate.is_view_telop) {
                makeBreakLineTelop(currentTemplate.telop);
            } else {
                makeBreakLineTelop("");
            }
        }

        // NEXCO様キャラクター選択時に呼出中と画面共有の待機中は手を振らないようにする
        const changeStandbyImgStatus = [SignageStateList.Active, SignageStateList.Calling];
        if (selectCharacter === "NE_custom" && changeStandbyImgStatus.includes(props.signageState)) {
            setStandbyImg(template.avatar.src.replace(/monitoringCharacter/g, `${selectCharacter}_shareScreenIdle`));
        } else {
            setStandbyImg(template.avatar.src.replace(/monitoringCharacter/g, selectCharacter));
        }

        setSignageContents({ ...template });
    }

    const staticWipeSize = props.inSignage ? 0.15 : 0.2;

    const getTopMargin = () => {
        let margin = 0;
        if (isWideWipeAvatar) {
            return `-${videoWidth * staticWipeSize}px`;
        }
        if (ratio.w >= ratio.h) {
            margin = (ratio.h / ratio.w + 1) * -1 * videoWidth + videoHeight;
        }
        return margin;
    }

    const getAvatarSize = (arg) => {
        let size = null;
        if (isWideWipeAvatar) {
            return `${videoWidth * staticWipeSize}px`;
        }

        switch (arg) {
            case "wrapper_w":
                size = signageContents.avatar.width >= 0 ? signageContents.avatar.width * videoHeight : "100%";
                break;
            case "wrapper_h":
                size = signageContents.avatar.height >= 0 ? signageContents.avatar.height * videoHeight : "";
                break;
            case "player_w":
                size = props.inTemplateEditor || props.inServiceOperation || ratio.w >= ratio.h ? videoHeight : "100%";
                break;
            case "player_h":
                size = ratio.w >= ratio.h ? videoWidth : "100%";
                break;
            default:
                throw new Error();
        }
        return size;
    }

    useEffect(() => {
        console.log(`[debug]standbyImg: ${standbyImg}`);
    }, [standbyImg]);


    // ストリームの表示スタイル
    const styles = {
        signage: {
            wrapper: {
                mt: "0",
                w: "100%"
            },
            video: {
                minW: "100%",
                minH: "100%",
                maxW: "100%",
                maxH: "100%",
            }
        }
    }

    //アバターのビデオを映ると、テンプレートのテキストとロゴが表示される
    const onHandleClickTouchButton = (button: string) => {
        props?.handleClickTouchButton && props.handleClickTouchButton(new Date().getTime(), button);
    }
    const video = document.getElementById('streamingVideo')
    video?.addEventListener('loadedmetadata', handleClickVideoLoader)

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    return (
        <Flex
            mt={styles.signage.wrapper.mt}
            bgColor="white"
            w={styles.signage.wrapper.w}
            flexDirection="column"
            justifyContent="center"
        >
            <Flex justifyContent="center">
                <Box
                    id="left-motion-panel"
                    bgColor="white"
                    minW="140px"
                    display={props.isViewTheirVideo && props.signageState === SignageStateList.Active ? "flex" : "none"}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="flex-start"
                >
                    <Box
                        id="left-motion-panel-spacer"
                        h="100px"
                        mb="4.5rem"
                        display="inline-block"
                    />
                    <Button
                        w="96px"
                        h="96px"
                        mt="1px"
                        mb="0.5rem"
                        bg=""
                        bgColor="teal.300"
                        // bgImage="url('/images/Common/motion-greeting.png')"
                        bgPosition="center"
                        bgRepeat="no-repeat"
                        bgSize="cover"
                        color="white"
                        onClick={() => sendMotionCommand("righthand")}
                    ><Flex flexDirection="column" alignItems="center">
                            <Text
                                fontSize="1.25rem">小さく</Text>
                            <ImArrowLeft size="1.5rem" />
                        </Flex>
                    </Button>
                    <Button
                        w="96px"
                        h="96px"
                        mt="1px"
                        mb="0.5rem"
                        bg=""
                        bgColor="teal.300"
                        // bgImage="url('/images/Common/motion-greeting.png')"
                        bgPosition="center"
                        bgRepeat="no-repeat"
                        bgSize="cover"
                        color="white"
                        onClick={() => sendMotionCommand("righthand-big")}
                    ><Flex flexDirection="column" alignItems="center">
                            <Text
                                fontSize="1.25rem">大きく</Text>
                            <ImArrowLeft size="2rem" />
                        </Flex>
                    </Button>
                </Box>
                <Flex
                    id="video-table"
                    position="relative"
                    bgColor="white"
                    h={props.inTemplateEditor || props.inServiceOperation || ratio.w >= ratio.h ? videoHeight : screenHeight >= 1000 ? "100vh" : `calc(${screenHeight}px - 50vh)`}
                    w={screenWidth >= 768 ? videoWidth : screenWidth}
                    border={props.borderNone ? "none" : "1px dotted gray"}
                >
                    <Flex
                        id="signage-view-container"
                        position="absolute"
                        h={props.inTemplateEditor || props.inServiceOperation ? videoHeight : screenHeight}
                        w={screenWidth >= 768 ? videoWidth : screenWidth}
                        top="0"
                        left="0"
                    >
                        <Flex
                            position="absolute"
                            paddingX={isVerticalButtonSetting ? 10 : 2}
                            paddingY={1}
                            bottom={0}
                            left={0}
                            zIndex={990}
                            width={"full"}
                            height={isVerticalButtonSetting ? '33%' : "80px"}
                            justifyContent="center"
                            alignItems="center"
                            direction={isVerticalButtonSetting ? 'column' : 'row'}
                            style={{ gap: "5px" }}
                        >
                            {props.signageState === SignageStateList.Standby && showButtonWithLayoutChange && settingsTouchButton?.map((button, key) => {
                                if (!button.visible) return <></>
                                return <React.Fragment key={key}>
                                    <Button
                                        width={(settingsTouchButton.filter((item) => item.visible).length === 1 && !isVerticalButtonSetting) ? '50%' : 'full'}
                                        rounded="lg"
                                        backgroundColor={button.color}
                                        _hover={{ background: button.color }}
                                        fontSize={button.fontSize}
                                        color="white"
                                        height={isVerticalButtonSetting ? 'inherit' : 'full'}
                                        whiteSpace='pre-line'
                                        pointerEvents={props.disableEventTouchButton ? 'none' : 'auto'}
                                        onClick={() => {
                                            if (props.disableEventTouchButton) return;
                                            onHandleClickTouchButton(button.textBtn);
                                        }}
                                    >
                                        {button.textBtn}
                                    </Button>
                                </React.Fragment>
                            })}
                        </Flex>
                        <Flex
                            id="pixel-streamer"
                            display={isViewPixelStreaming ? "flex" : "none"}
                            position="absolute"
                            zIndex={signageContents.avatar.zIndex ? signageContents.avatar.zIndex : ""}
                            minW={styles.signage.video.minW}
                            minH={styles.signage.video.minH}
                            maxW={styles.signage.video.maxW}
                            maxH={styles.signage.video.maxH}
                            justifyContent="center"
                            alignItems="flex-end"

                        >
                            <Flex
                                id="avatar-size-wrapper"
                                position="absolute"
                                w={getAvatarSize("wrapper_w")}
                                h={getAvatarSize("wrapper_h")}
                                top={signageContents.avatar.top}
                                bottom={signageContents.avatar.bottom}
                                left={signageContents.avatar.left}
                                right={signageContents.avatar.right}
                                zIndex="10"
                            >
                                <Flex
                                    id="player"
                                    // w="100%"
                                    // h="100%"
                                    w={getAvatarSize("player_w")}
                                    h={getAvatarSize("player_h")}
                                    transform={ratio.w < ratio.h ? "" : `rotate(90deg)`}
                                    transformOrigin={`left bottom`}
                                    mt={getTopMargin()}
                                />
                            </Flex>
                            {/* PixelStreamのデコードができない場合のローディング表示
                            [tmp]NE_customのみ対応 2023.02.24 矢葺 */}
                            <Flex
                                id="loading-view"
                                display={(isViewPixelStreaming && !props.isShareScreen && isLoadingView) || selectCharacter === null ? "flex" : "none"}
                                position="absolute"
                                zIndex={isLoadingView ? "15" : "5"}
                                bgColor="white"
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                w="100%"
                                h="100%"
                                padding="5%"
                            >
                                <Image
                                    src='/images/Common/loading.svg'
                                    w={ratio.h > ratio.w ? "100%" : "50%"}
                                    objectFit="contain"
                                />
                                <Text
                                    fontSize="xl"
                                    color="gray.500"
                                >
                                    Now Loading...</Text>
                            </Flex>
                        </Flex>

                        <Flex
                            id="standby-video"
                            display={!isViewPixelStreaming && signageContents.avatar.isDisp && selectCharacter ? "flex" : "none"}
                            position="relative"
                            zIndex={signageContents.avatar.zIndex ? signageContents.avatar.zIndex : ""}
                            minW={styles.signage.video.minW}
                            minH={styles.signage.video.minH}
                            maxW={styles.signage.video.minW}
                            maxH={styles.signage.video.minH}
                            justifyContent="center"
                            alignItems="flex-end"
                        >
                            <Flex
                                position="absolute"
                                w={signageContents.avatar.width >= 0 ? `calc(${signageContents.avatar.width}*${videoHeight}px)` : "100%"}
                                h={signageContents.avatar.height >= 0 ? `calc(${signageContents.avatar.height}*${videoHeight}px)` : ""}
                                top={signageContents.avatar.top}
                                bottom={signageContents.avatar.bottom}
                                left={signageContents.avatar.left}
                                right={signageContents.avatar.right}
                            >
                                <video muted autoPlay loop ref={videoRef} style={{
                                    objectFit: 'cover',
                                    height: "100%",
                                    width: "100%",
                                    objectPosition: '50% 50%'
                                }}>
                                    <source src={standbyImg} type="video/mp4" />
                                    {/* <source src={signageContents.media.src} type="video/mp4" /> */}
                                </video>
                            </Flex>
                        </Flex>
                        <Flex
                            id="contents-media"
                            position="absolute"
                            justifyContent="center"
                            display={signageContents.media.isDisp ? "absolute" : "none"}
                            zIndex={signageContents.media.zIndex ? signageContents.media.zIndex : ""}
                            w={signageContents.media.width}
                            h={signageContents.media.height}
                            top={signageContents.media.top}
                            bottom={signageContents.media.bottom}
                            left={signageContents.media.left}
                            right={signageContents.media.right}
                        >
                            {signageMediaType === "Video" ?
                                <video muted autoPlay loop ref={mediaVideoRef} style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                                    <source src={signageContents.media.src} type="video/mp4" />
                                </video> :
                                <Image
                                    src={signageContents.media.src}
                                    style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                                />
                            }
                        </Flex>
                        <Flex
                            id="share-media-container"
                            position="absolute"
                            justifyContent="center"
                            display={props.isShareScreen}
                            zIndex={signageContents.media.zIndex ? signageContents.media.zIndex : ""}
                            w={signageContents.media.width}
                            h={signageContents.media.height}
                            top={signageContents.media.top}
                            bottom={signageContents.media.bottom}
                            left={signageContents.media.left}
                            right={signageContents.media.right}
                        >
                            <video id="share-media" playsInline muted style={{ objectFit: 'contain', width: '100%', height: '100%', display: 'none' }} />
                        </Flex>
                        <Image
                            zIndex={isLoadingVideo ? "999" : ""}
                            id="signage-logo"
                            position="absolute"
                            display={signageContents.logo.isDisp ? "inline" : "none"}
                            // display="none"
                            src={signageContents.logo.src}
                            maxW={signageContents.logo.width}
                            maxH="6vh"
                            top={signageContents.logo.top}
                            bottom={signageContents.logo.bottom}
                            left={signageContents.logo.left}
                            right={signageContents.logo.right}
                        ></Image>
                        <Flex id="talk-telop-wrapper"
                            position="absolute"
                            top="20vh"
                            w="45%"
                            h="73vh"
                            pl="0.5rem"
                            zIndex="1000"
                            // bgColor="blue"
                            flexDirection="column"
                            display={props.signageState === SignageStateList.Standby || props.signageState === SignageStateList.Crowded ? "" : "none"}
                        >
                            <Flex
                                visibility="hidden"
                                zIndex={isLoadingVideo ? "999" : ""}
                                justifyContent="start"
                                position="relative"
                                bgColor="#FFFFFF"
                                color="#004400"
                                h="auto"
                                w="90%"
                                padding="10px"
                                marginBottom="10px"
                                borderRadius="10px">
                                お客様の話しやすい言語でお話しください。
                            </Flex>
                            <Flex
                                id="talk-telpop-transelateLang"
                                flexDirection="row"
                                visibility="hidden"
                            >
                                <Flex
                                    zIndex={isLoadingVideo ? "999" : ""}
                                    justifyContent="center"
                                    bgColor="#008080"
                                    color="#FFFFFF"
                                    textAlign="center"
                                    h="50px"
                                    w="50%"
                                    padding="12px"
                                    margin="0px 5px 20px 5px"
                                    borderRadius="30px">
                                    English
                                </Flex>
                                <Flex
                                    zIndex={isLoadingVideo ? "999" : ""}
                                    justifyContent="center"
                                    bgColor="#008080"
                                    color="#FFFFFF"
                                    textAlign="center"
                                    h="50px"
                                    w="20%"
                                    padding="12px"
                                    margin="0px 5px 20px 5px"
                                    borderRadius="30px">
                                    中
                                </Flex>
                                <Flex
                                    zIndex={isLoadingVideo ? "999" : ""}
                                    justifyContent="center"
                                    bgColor="#008080"
                                    color="#FFFFFF"
                                    textAlign="center"
                                    h="50px"
                                    w="20%"
                                    padding="12px"
                                    margin="0px 5px 20px 5px"
                                    borderRadius="30px">
                                    韓
                                </Flex>
                            </Flex>
                            <Flex
                                id="system-talk-01"
                                // AI会話が存在するときのみ表示する
                                display={systemTalkList.length > 0 ? "" : "none"}
                                zIndex={isLoadingVideo ? "999" : ""}
                                justifyContent="start"
                                position="relative"
                                bgColor="#008080"
                                color="#FFFFFF"
                                h="auto"
                                w="90%"
                                left="20px"
                                padding="10px"
                                marginBottom="10px"
                                borderRadius="10px">
                                {getSystemTalk(1)}
                            </Flex>
                            <Flex
                                id="user-talk-01"
                                // talkStep % 3 >= 1のときのみ表示
                                display={talkStep % 3 >= 1 ? "" : "none"}
                                zIndex={isLoadingVideo ? "999" : ""}
                                justifyContent="start"
                                position="relative"
                                bgColor="#FFFFFF"
                                color="#004400"
                                h="auto"
                                w="90%"
                                padding="10px"
                                marginBottom="10px"
                                borderRadius="10px">
                                {getUserTalk(1)}
                            </Flex>
                            <Flex
                                id="system-talk-02"
                                // talkStep % 3 >= 2のときのみ表示
                                display={talkStep % 3 >= 2 ? "" : "none"}
                                zIndex={isLoadingVideo ? "999" : ""}
                                justifyContent="start"
                                position="relative"
                                bgColor="#008080"
                                color="#FFFFFF"
                                h="auto"
                                w="90%"
                                left="20px"
                                padding="10px"
                                marginBottom="10px"
                                borderRadius="10px">
                                {getSystemTalk(2)}
                            </Flex>
                        </Flex>
                        <Flex
                            id="talk-telop-call"
                            display={props.signageState === SignageStateList.Standby ? "flex" : "none"}
                            position="absolute"
                            bottom="0"
                            w="100%"
                            h="6vh"
                            zIndex="1000"
                            bgColor="#FFFFFF"
                            direction="row"
                            justifyContent="right"
                        >
                            <Text
                                zIndex={isLoadingVideo ? "999" : ""}
                                position="relative"
                                textAlign="center"
                                // color="#008080"
                                h="6vh"
                                w="50%"
                                margin="5px"
                                padding="10px">
                                スタッフとの会話はこちらから
                            </Text>
                            <Button
                                zIndex={isLoadingVideo ? "999" : ""}
                                position="relative"
                                textAlign="center"
                                bgColor="red.400"
                                color="#FFFFFF"
                                h="5vh"
                                w="50%"
                                padding="12px"
                                margin="5px"
                                borderRadius="10px"
                                onClick={() => {
                                    onHandleClickTouchButton("AIから引き継ぎ");
                                }}
                                >
                                呼び出し
                            </Button>
                        </Flex>
                        <Flex
                            // display={telopList.length > 0 ? "flex" : "none"}
                            id="fix-message-box"
                            display={props.signageState !== SignageStateList.Standby && props.signageState !== SignageStateList.Crowded ? "" : "none"}
                            // zIndex={isLoadingVideo ? "999" : ""}
                            zIndex="999"
                            flexDirection="column"
                            justifyContent="center"
                            position="absolute"
                            // display={signageContents.text.isDisp ? "flex" : "none"}
                            // display="flex"
                            bgColor={signageContents.text.bgColor}
                            // width={ props.signageState === SignageStateList.Standby ? signageContents.text.width : ""}
                            width={signageContents.text.width}
                            top={signageContents.text.top >= 0 ? `calc(${signageContents.text.top}*${videoHeight}px)` : ""}
                            bottom={signageContents.text.bottom >= 0 ? `calc(${signageContents.text.bottom}*${videoHeight}px)` : ""}
                            left={signageContents.text.left >= 0 ? `calc(${signageContents.text.left}*${videoHeight}px)` : ""}
                            right={signageContents.text.right >= 0 ? `calc(${signageContents.text.right}*${videoHeight}px)` : ""}
                            p={signageContents.text.padding}>

                            {telopList.map((row) =>
                                <Text
                                    textAlign={signageContents.text.width === "100%" ? "center" : "left"}
                                    color={signageContents.text.color}
                                    fontSize={signageContents.text.fontSize * videoHeight}
                                >{row}</Text>
                            )}
                        </Flex>
                        <Flex
                            justifyContent="right"
                            position="absolute"
                            bottom="0"
                            right="0"
                            zIndex="991"
                            color="gray.600"
                            // 翻訳テロップ表示のON/OFF切り替え
                            display="none"
                        >
                            <Text
                                mr="0.5rem"
                            >
                                telop
                            </Text>

                            <Text
                                fontWeight={isViewTranslateTelop ? "" : "bold"}
                            >
                                OFF
                            </Text>
                            <Switch
                                ml="0.5rem"
                                colorScheme="gray"
                                isChecked={isViewTranslateTelop}
                                onChange={(e) => {
                                    setIsViewTranslateTelop(e.target.checked);
                                }}
                            />
                            <Text
                                ml="0.5rem"
                                fontWeight={isViewTranslateTelop ? "bold" : ""}>
                                ON
                        </Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex
                    id="video-table02"
                    position="relative"
                    bgColor="white"
                    // h={props.inTemplateEditor || props.inServiceOperation || ratio.w >= ratio.h ? videoHeight : screenHeight >= 1000 ? "100vh" : `calc(${screenHeight}px - 50vh)`}
                    // w={screenWidth >= 768 ? videoWidth : screenWidth}
                    h="100vh"
                    w="70%"
                    border={props.borderNone ? "none" : "1px dotted gray"}
                >
                   <Text
                    color="green.300"
                    display="none"
                   >{textFloorMap}</Text>
                   <Image 
                    src={textFloorMap}
                    objectFit="contain"
                    width="100%"/>
                </Flex>
                <Box
                    bgColor="white"
                    minW="140px"
                    display={props.isViewTheirVideo && props.signageState === SignageStateList.Active ? "flex" : "none"}
                    flexDirection="column"
                    alignItems="center"
                >
                    <Text fontWeight="bold" h="2rem">操作パネル</Text>
                    <Button
                        w="96px"
                        h="96px"
                        mb="0.5rem"
                        bg=""
                        bgColor="teal.300"
                        // bgImage="url('/images/Common/motion-greeting.png')"
                        bgPosition="center"
                        bgRepeat="no-repeat"
                        bgSize="cover"
                        color="white"
                        onClick={() => toggleIsShareScreen()}
                    >{isShareScreen ? "共有終了" : "画面共有"}</Button>
                    <Separator mt="1rem" mb="1rem" />

                    <Button
                        display={isDispFullMotionCharacters.includes(selectCharacter) ? "" : "none"}
                        w="96px"
                        h="96px"
                        mb="0.5rem"
                        bg=""
                        bgColor="teal.300"
                        bgImage=""
                        bgPosition="center"
                        bgRepeat="no-repeat"
                        bgSize="cover"
                        color="white"
                        onClick={() => sendMotionCommand("lefthand")}
                    ><Flex flexDirection="column" alignItems="center">
                            <Text
                                fontSize="1.25rem">小さく</Text>
                            <ImArrowRight size="1.5rem" />
                        </Flex></Button>
                    <Button
                        display={isDispFullMotionCharacters.includes(selectCharacter) ? "" : "none"}
                        w="96px"
                        h="96px"
                        mb="0.5rem"
                        bg=""
                        bgColor="teal.300"
                        bgImage=""
                        bgPosition="center"
                        bgRepeat="no-repeat"
                        bgSize="cover"
                        color="white"
                        onClick={() => sendMotionCommand("lefthand-big")}
                    ><Flex flexDirection="column" alignItems="center">
                            <Text
                                fontSize="1.25rem">大きく</Text>
                            <ImArrowRight size="2rem" />
                        </Flex></Button>
                    <Button
                        display={isDispFullMotionCharacters.includes(selectCharacter) ? "" : "none"}
                        w="96px"
                        h="96px"
                        mb="0.5rem"
                        bg=""
                        bgColor="teal.300"
                        bgImage=""
                        bgPosition="center"
                        bgRepeat="no-repeat"
                        bgSize="cover"
                        color="white"
                        onClick={() => sendMotionCommand("wavehand")}
                    >手を振る</Button>
                    <Button
                        w="96px"
                        h="96px"
                        mb="0.5rem"
                        bg=""
                        bgColor="teal.300"
                        bgImage=""
                        bgPosition="center"
                        bgRepeat="no-repeat"
                        bgSize="cover"
                        color="white"
                        onClick={() => sendMotionCommand("greeting")}
                    >おじぎ</Button>
                    <Flex
                        id="signage-debug-info"
                        display="none"
                    >
                        <Text>state: {props.signageState}</Text>
                        <Text>layout: {currentTemplate ? currentTemplate.layout : ""}</Text>
                        <Text>isMonitoring: {props.isMonitoring ? "true" : "false"}</Text>
                    </Flex>
                </Box>
            </Flex>
            {/* EDIT by Akiya Souken START */}
            {/* iPhone on / off control */}
            <Flex
                id="capture-panel"
                justifyContent="center"
                display={props.inServiceOperation ? "block" : "none"}
                mt="2rem"
            >
                <div id="liveView" className="videoView">
                    <Text
                    >
                        キャプチャモード
                        </Text>
                    <Flex
                        justifyContent="center">

                        <Text
                            fontWeight={captureModeRef.current === "FaceUseWebcam" ? "" : "bold"}
                        >
                            iPhone
                        </Text>
                        <Switch
                            ml="0.5rem"
                            colorScheme="teal"
                            isChecked={captureModeRef.current === "FaceUseWebcam"}
                            onChange={toggleBrowserCapture}
                        />
                        <Text
                            ml="0.5rem"
                            fontWeight={captureModeRef.current === "FaceUseWebcam" ? "bold" : ""}>
                            webカメラ
                        </Text>
                    </Flex>


                    {/* <button className="tooltip" id="iPhoneButton">
                        <span className="mdc-button__label">Use iPhone</span>
                        <span className="tooltiptext">Click after connect to server</span>
                    </button> */}

                    {/* <label>
                        Subject Name
    						<input type="text" id="liveLinkSubjectName" value="iPhone" /></label> */}
                    <div id="liveView" className="videoView">
                        <button id="webcamButton" className="tooltip" style={{ display: 'none' }}>
                            <span className="mdc-button__ripple"></span>
                            <span className="mdc-button__label">ENABLE WEBCAM</span>
                        </button>
                        <div style={{ position: 'relative', textAlign: 'center' }}>
                            <video id="webcam" autoPlay playsInline style={{ position: 'absolute', left: '12vw', top: '0px' }}></video>
                            <canvas className="output_canvas" id="output_canvas" style={{ position: 'absolute', left: '12vw', top: '0px' }}></canvas>
                        </div>
                    </div>
                    {/* EDIT by Akiya Souken END */}
                </div>
            </Flex>
            <Flex
                id="config-container"
                display={props.isViewSignageConfig ? "flex" : "none"}
                flexDirection="column"
            >
                <Text fontWeight="bold">サイネージ設定</Text>
                <Flex w="90%" justifyContent="space-around">
                    {commandList.map((cmdObj) =>
                        <Button
                            w="100px"
                            h="100px"
                            bg=""
                            bgColor="teal.300"
                            bgImage={`url('${cmdObj.bgImage}')`}
                            bgPosition="center"
                            bgRepeat="no-repeat"
                            bgSize="cover"
                            color="white"
                            onClick={() => sendToStreamer(cmdObj.cmd)}
                        >
                            <Text w="100%" display="block" whiteSpace="normal">
                                {cmdObj.dispName}
                            </Text>
                        </Button>
                    )}
                </Flex>
                <div id="qualityStatus"></div>
                <div id="stats"></div>
            </Flex>
        </Flex>
    )
}